import { Injectable } from '@angular/core';
import { ICommunity } from '@modules/communities/models/communities.model';
import { BehaviorSubject, Observable } from 'rxjs';

export interface CommunitySelectionForCreateDialogSettings {
    isOpen: boolean;
    callback: (selected: ICommunity | null) => void;
}

const emptyCallback = (_: ICommunity | null): void => {};

@Injectable({
    providedIn: 'root',
})
export class SidebarService {
    private _communitySelectionForCreateDialogSettings$ =
        new BehaviorSubject<CommunitySelectionForCreateDialogSettings>({
            isOpen: false,
            callback: emptyCallback,
        });

    get communitySelectionForCreateDialogSettings$(): Observable<CommunitySelectionForCreateDialogSettings> {
        return this._communitySelectionForCreateDialogSettings$.asObservable();
    }

    openCommunitySelectionForCreateDialog(): Observable<ICommunity> {
        return new Observable((subs) => {
            const callback = (selected: ICommunity | null): void => {
                if (selected) {
                    subs.next(selected);
                } else {
                    this._communitySelectionForCreateDialogSettings$.next({
                        isOpen: false,
                        callback: emptyCallback,
                    });
                }
                subs.complete();
            };

            this._communitySelectionForCreateDialogSettings$.next({
                isOpen: true,
                callback,
            });
        });
    }
}
